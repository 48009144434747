import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { getToken } from '../utils/get-token';
import apolloClientInit from '../utils/apollo-client';
import { userCookies } from '@/shared/constants/cookies';
import { useConfigStore } from '../pinia/modules/config';
import { useUserStore } from '../pinia/modules/user';
import { useCartStore } from '../pinia/modules/cart';

const initHobbiiStarter = async () => {
  const alreadyInitialized = !!window.hobbiiStore;

  if (!alreadyInitialized) {
    window.hobbiiStore = createPinia();

    const graphqlHost = import.meta.env.VITE_HOBBII_GATEWAY;

    window.hobbiiStore.use(() => ({
      apolloClient: apolloClientInit({ graphqlHost })
    })); // Apollo Client plugin

    // Set active Pinia instance.
    // Pinia needs access to an app to be available everywhere.
    createApp().use(window.hobbiiStore);

    const configStore = useConfigStore();
    const userStore = useUserStore();
    if (window.Hobbii.hobbiiStoreData) {
      const { countryCode, language, isLogged, pointsMultiplier } =
        window.Hobbii.hobbiiStoreData;

      configStore.$patch({
        countryCode,
        language,
        graphqlHost,
        pointsMultiplier
      });

      if (isLogged) {
        if (!sessionStorage.getItem(userCookies.CUSTOMER_DATA)) {
          let token = await getToken();
          if (!token) {
            token = await getToken(true);
          }

          userStore.setCustomer(token);
        }
      } else {
        userStore.cleanCustomer();
      }
    }

    const customerDataJson = sessionStorage.getItem(userCookies.CUSTOMER_DATA);
    if (customerDataJson) {
      userStore.customer = JSON.parse(customerDataJson);
    }

    configStore.getStarterData();

    const cartStore = useCartStore();
    cartStore.getCart();
  }
};

export { initHobbiiStarter };
